import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { FormsModules } from '@utils/shared-modules';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-autocomplete-select',
  standalone: true,
  imports: [CommonModule, FormsModules, TranslateModule],
  templateUrl: './autocomplete-select.component.html',
  styleUrl: './autocomplete-select.component.scss',
})
export class AutocompleteSelectComponent {
  @Input() form!: FormGroup;
  @Input() selectedFormControlName: string = '';
  @Input() items: any[] = [];
  @Input() labelProperty?: string;
  @Input() valueProperty?: string;
  @Input() useValueProperty = false;

  filteredItems: any[] = [];

  @ViewChild('input') input?: ElementRef<HTMLInputElement>;

  filter(reset = false) {
    if (reset) {
      this.filteredItems = this.items.slice();
      return;
    }

    const filterValue = this.input?.nativeElement.value.toLowerCase();
    if (filterValue) {
      this.filteredItems = this.items.filter((option) => {
        if (this.labelProperty) {
          return (
            option[this.labelProperty]
              .toLowerCase()
              .indexOf(filterValue.toLowerCase()) >= 0
          );
        }

        return option.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0;
      });
    } else {
      this.filteredItems = this.items.slice();
    }
  }

  checkValue() {
    setTimeout(() => {
      let value = this.form.controls[this.selectedFormControlName].value;

      if (this.useValueProperty && this.valueProperty) {
        value = this.items.find((item) => item[this.valueProperty!] === value);
      }

      if (value && this.labelProperty) {
        value = value[this.labelProperty];
      }

      const filterValue = this.input?.nativeElement.value.toLowerCase();

      if (
        typeof filterValue === 'string' &&
        (!value || value.toLowerCase() !== filterValue)
      ) {
        this.form.controls[this.selectedFormControlName].setValue(null);
        this.form.controls[
          this.selectedFormControlName
        ].updateValueAndValidity();
      }
    }, 100);
  }

  displayFn(item: any | null): string {
    if (item) {
      if (this.useValueProperty && this.valueProperty) {
        item = this.items.find(
          (option) => option[this.valueProperty!] === item,
        );

        if (!item) {
          return '';
        }
      }

      if (this.labelProperty) {
        return item[this.labelProperty];
      }
      return item;
    }

    return '';
  }
}
